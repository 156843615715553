import React from "react";
import Img from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import Layout from "../components/layout";

const HomepageTemplate = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout featuredImagePath="above-min.png">
      {/* Halloween special section */}
      {/* <Row className="halloween">
        <div className="hallotext">
          <h1>No trick, all treat.</h1>
          <p>Share a Dagcoin Gift Card this Halloween…</p>
        </div>
        <div className="pumpkin"><Img fluid={data.pumpkin.childImageSharp.fluid} /></div>
        <Img fluid={data.bgabove.childImageSharp.fluid} />
        <a href="https://app.giftcard.dagcoin.org/?type=physical">Shop gift cards</a>
      </Row> */}
      {/* Special offer section */}
      {/* <Row className="limited-time-offer"> 
 <div className="hero__text-holder home-top">
                <h1 className="hero__title">Limited time offer!</h1>
                 <p className="hero__subtitle">A nice copy for clients to be more eager to buy</p> 
              </div>
              <Row className="limited-below">
    <Col>
    <div className="colheader">
    <div className="col-img"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <h3>50 DAG</h3>
    </div>
    <ul>
      <li>Get a 50 DAG gift card</li>
      <li>Get an extra gift card worth 10 DAGS <strong>for free</strong></li>
    </ul>
    <div className="offerone"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <a href="https://app.giftcard.dagcoin.org/?type=physical">BUY</a>
    </Col>
    <Col>
    <div className="colheader">
    <div className="col-img"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <h3>100 DAG</h3>
    </div>
    <ul>
      <li>Get a 100 DAG gift card</li>
      <li>Get three extra gift cards worth 10 DAGS each <strong>for free</strong></li>
    </ul>
    <div className="offertwo"><Img fluid={data.GiftImage.childImageSharp.fluid}/><Img fluid={data.GiftImage.childImageSharp.fluid}/><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <a href="https://app.giftcard.dagcoin.org/?type=physical">BUY</a>
    </Col>
    <Col>
    <div className="colheader">
    <div className="col-img"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <h3>250 DAG</h3>
    </div>
    <ul>
      <li>Get a 250 DAG gift card</li>
      <li>Get an extra gift card worth 50 DAGS <strong>for free</strong></li>
    </ul>
    <div className="offerthree"><Img fluid={data.GiftImage.childImageSharp.fluid}/></div>
    <a href="https://app.giftcard.dagcoin.org/?type=physical">BUY</a>
    </Col>
  </Row>
  </Row>        */}
      <Row className="hero home-above">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero__text-holder home-top">
                <h1 className="hero__title">{t("hero.title")}</h1>
                <p className="hero__subtitle"> {t("hero.description")}</p>
              </div>
              <div className="home-middle">
                <div className="col-lg-6">
                  <a href="https://app.giftcard.dagcoin.org/?type=egift">
                    <Img fluid={data.SendAs.childImageSharp.fluid} />
                    <p>{t("middle.paragraphFirst")}</p>
                    <h3>{t("middle.linkFirst")}</h3>
                  </a>
                </div>
                <div className="col-lg-6">
                  <a href="https://app.giftcard.dagcoin.org/?type=physical">
                    <Img fluid={data.GetAs.childImageSharp.fluid} />
                    <p>{t("middle.paragraphSecond")}</p>
                    <h3>{t("middle.linkSecond")}</h3>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="home-bottom">
                <div className="col-lg-4">
                  <h3>{t("columns.titleOne")}</h3>
                  <p>{t("columns.paragraphOne")}</p>
                </div>
                <div className="col-lg-4">
                  <h3>{t("columns.titleTwo")}</h3>
                  <p>{t("columns.paragraphTwo")}</p>
                </div>
                <div className="col-lg-4">
                  <h3>{t("columns.titleThree")}</h3>
                  <p>{t("columns.paragraphThree")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row className="way">
        <div className="col-lg-12">
          <div className="col-lg-6">
            <h3>{t("way.title")}</h3>
            <p>{t("way.paragraph")}</p>
            <a href="https://dagcoin.org">{t("way.link")}</a>
          </div>
        </div>
        <div class="hand-way">
          <Img fluid={data.Hand.childImageSharp.fluid} />
        </div>
      </Row>
      <Row className="four-items">
        <div className="col-lg-12">
          <div class="col-lg-3">
            <Img fluid={data.Custom_one.childImageSharp.fluid} />
            <p>{t("fourItems.columnOne")}</p>
          </div>
          <div class="col-lg-3">
            <Img fluid={data.Custom_two.childImageSharp.fluid} />
            <p>{t("fourItems.columnTwo")}</p>
          </div>
          <div class="col-lg-3">
            <Img fluid={data.Custom_three.childImageSharp.fluid} />
            <p>{t("fourItems.columnThree")}</p>
          </div>
          <div class="col-lg-3">
            <Img fluid={data.Custom_four.childImageSharp.fluid} />
            <p>{t("fourItems.columnFour")}</p>
          </div>
        </div>
      </Row>
      <Row className="growth">
        <div className="col-lg-12">
          <div className="col-lg-6">
            <h3>{t("growth.title")}</h3>
            <p>{t("growth.paragraph")}</p>
            <a href="https://dagcoin.org">{t("growth.link")}</a>
          </div>
          <div className="col-lg-6 boximage">
            <Img fluid={data.GiftBox.childImageSharp.fluid} />
          </div>
        </div>
      </Row>
      <Row className="prefooter">
        <div className="col-lg-12">
        <h3>{t("preFooter.title")}</h3>
            <p>{t("preFooter.paragraph")}</p>
                    <a href="https://app.giftcard.dagcoin.org/?type=egift">{t("preFooter.linkEgift")}</a>
                    <a href="https://app.giftcard.dagcoin.org/?type=physical" className="home-second-pop">{t("preFooter.linkPhysical")}</a>
            </div>            
        </Row>
    </Layout>
  );
};
export default HomepageTemplate;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pumpkin: file(relativePath: { eq: "pumpkin.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgabove: file(relativePath: { eq: "bgabove.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GiftImage: file(relativePath: { eq: "giftcard.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SendAs: file(relativePath: { eq: "mac2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GiftBox: file(relativePath: { eq: "gift-box-PS4UP4U.jpg" }) {
      childImageSharp {
        fluid (quality: 100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    GetAs: file(relativePath: { eq: "card2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Hand: file(relativePath: { eq: "hand.png" }) {
      childImageSharp {
        fluid (quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Custom_one: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid (quality: 100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_two: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid (quality: 100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_three: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid (quality: 100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_four: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid (quality: 100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    arrow_right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
